/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.top-nav').attr('data-height', $('.top-nav').height() + 30);

        // We remove empty p tags
        $('p').each(function() {
          var $this = $(this);
          if($this.html().replace(/\s|&nbsp;/g, '').length === 0) {
            $this.remove();
          }
        });
        // Add img-responsive class to all images
        $('img').addClass('img-responsive');


        // This activates the animated Burger Menu
        function activateHamburger() {
          var $hamburger = $('.hamburger');
          $hamburger.on('click', function(e) {
            $hamburger.toggleClass('is-active');

            // Do something else, like open/close menu
            $('body').toggleClass('burger-open');
            $('#menu-main-navigation-container').collapse('toggle');

          });
        }
        activateHamburger();

        function createSubToggle(){
          $('.nav .menu-item-has-children').prepend('<span class="nav-toggle"></span>');
        }
        function removeSubToggle(){
          $('.nav .nav-toggle').remove();
        }

        function toggleSubNav(){
          if ($(window).width() > 991) {
            $(document).on('click', '.nav-toggle', function(){

              if ($(this).siblings('.dropdown-menu').hasClass('show')) {
                $(this).siblings('.dropdown-menu').removeClass('show');
                $(this).removeClass('nav-toggled');
              } else {
                $(this).addClass('nav-toggled');
                $(this).siblings('.dropdown-menu').addClass('show');
              }
            });
            // $(document).on('mouseleave mousein', '.dropdown-menu', function(){
            //   console.log('left');
            //   $('.nav-toggle').removeClass('nav-toggled');
            //   $(this).hide();
            // });
          }
        }
        toggleSubNav();

        if ($(window).width() > 991) {
          createSubToggle();
        } else {
          removeSubToggle();
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        function makeTopNavSticky() {
          if ($(window).width() > 991) {
            $(window).scroll(function() {

              if ( window.scrollY >= 250 ) {
                var topNavHeight = $('.top-nav').height();
                $('.top-nav').addClass('scrolled').attr('data-height', $('.top-nav').height());
              } else {
                $('.top-nav').removeClass('scrolled').attr('data-height', $('.top-nav').height() + 30);
              }
            });
          }
        }
        //makeTopNavSticky();

        $('.link-contact').click(function(){
          location.href = "/kontakt";
        });

        $('.link-unternehmen').click(function(){
          location.href = "/unternehmer";
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // Click on Button to go to contact
        $('.distractor .inner').click(function(){
          location.href = "/kontakt";
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'kontakt': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        function initialize_gmaps() {

          var styleArray = [
            {
              featureType: 'all',
              stylers: [
                { saturation: -80 }
              ]
            },{
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                { hue: '#058893' },
                { saturation: 50 }
              ]
            },{
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [
                { visibility: 'off' }
              ]
            }
          ];
          var sewing = {
            lat: 54.3134141,
            lng: 10.1267944
          };

          var icon = window.location.origin + '/wp-content/themes/sewing-theme/dist/images/marker.svg';

          var mapOptions = {
            zoom: 14,
            center: sewing,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false,
            styles: styleArray
          };

          var map = new google.maps.Map(document.getElementById('map'),
          mapOptions);

          var marker = new google.maps.Marker({
            position: sewing,
            map: map,
            icon: icon
          });

          var contentString_sewing = '<div id="content">'+
          '<div id="siteNotice"></div><b>Personaldienstleistungen SEWING</b><div id="bodyContent"><p class="sewing_text">Hopfenstraße 1 d<br>24114 Kiel<br><br>Telefon:+49 (0) 431 997 651 30<br>E-Mail:info@sewing-personal.de</p></div></div>'; 
          var infowindow_sewing = new google.maps.InfoWindow({
            content: contentString_sewing
          });

          marker.addListener('click', function() {
            infowindow_sewing.open(map, marker);
          });

        }

        google.maps.event.addDomListener(window, 'load', initialize_gmaps);
        //map.refresh();


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
